import { getImage, GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { motion } from "framer-motion";
import { Link } from "gatsby";

const PartnerCard = (props) => {
    
    const data = props.data
    const img = data.featuredImage !== null ? data.featuredImage.node.mediaItemUrl : null
    const delay = props.delay * .3

    return(
        <a href={data?.partners?.website} target="_blank" className="h-full">
        <motion.div initial={{ translateY: -100, opacity: 0 }} animate={{ translateY: 0, opacity: 1 }} transition={{ duration: .3, delay: delay }} className="flex lg:flex-row flex-col h-full lg:justify-between justify-start shadow items-center">
            
            <div className="partner_img lg:h-full xl:w-4/12 lg:w-full bg-white overflow-hidden">
                <img src={img} className="lg:object-cover object-contain lg:h-full h-full w-full object-center m-0" ></img>
            </div>
            <div className="lg:w-full px-8 py-4">
                <h3 className="text-black">{data.title}</h3>
                <div dangerouslySetInnerHTML={{__html: data.content }} className="text-black"></div>
                {/* <a href="/">Lees meer..</a> */}
            </div>
        </motion.div>
        </a>
    )
}

export default PartnerCard;