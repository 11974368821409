import React, { useContext } from "react";
import Container from "../components/Container/Container";
import PartnerCard from "../components/Partners/PartnerCard";
import SmallHero from "../components/SmallHero/SmallHero";
import SmallHeroNew from "../components/SmallHeroNew/SmallHeroNew";
import { usePartnerQuery } from "../hooks/usePartnerQuery";
import VestigingContext from "../store/vestiging-context";
import G from "../images/groenhout_g.png"
import img from "../images/gewoon-verdiend.jpg"

const PartnerPage = () => {

    const data = usePartnerQuery()
    const ctx = useContext(VestigingContext);
    
    const partners = data.allWpPartner.edges
    console.log(partners)
    const showPartners = ctx.vestiging !== undefined ? partners.filter((e) => {
        if (e.node.categories.nodes.some(e => e.name === ctx.vestiging)) {
            return true;
        }else{
            return false
        }
    }) : partners
    
    const partnerContent = showPartners.map((e, i) => (
        <PartnerCard data={e.node} delay={i}></PartnerCard>
    ))

    return (
        <>
            <SmallHeroNew title={'Gepassioneerd, lokaal en gezamenlijk'} desc="Bij Groenhout Makelaars beschikken we over een uitgebreid netwerk van samenwerkingspartners. Samen bieden we u de best denkbare dienstverlening als het om woonwensen gaat. Maar onze partners zijn niet alleen businesspartners, want samen steunen wij sinds 2008 het goede doel Gewoon Verdiend." overlay={true} overlayImage={G} image={img !== undefined ? img : ''}></SmallHeroNew>
            <Container styles={`my-16 px-8`}>
                <div className="w-6/12  text-left ">
                    <h2>Partners</h2>
                    {/* <p >Bij Groenhout Makelaars beschikken we over een uitgebreid netwerk van samenwerkingspartners. Samen bieden we jou de best denkbare dienstverlening als het om woonwensen gaat. Maar onze partners zijn niet alleen businesspartners, want samen steunen wij sinds 2008 het goede doel Gewoon Verdiend.</p> */}
                </div>

                <div className="lg:grid lg:grid-cols-2 flex flex-col gap-8 mt-16">
                    {partnerContent}
                </div>
            </Container>
        </>
    )
}

export default PartnerPage;